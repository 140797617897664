.navbar-title {
    text-decoration: none;
    color: white;
    font-family: "Paytone One", sans-serif;
}

.navbar-link {
    text-decoration: none;
    color: white;
    font-size: 18px;
    font-family: "Paytone One";
}
